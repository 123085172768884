import { ReactElement, useEffect, useState } from 'react'
import { ContextProps, createContext } from '../utils/contextHelper'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'

interface SignalRContextState {
  signalRConnection: HubConnection | null
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const [Provider, useSignalRContext] = createContext<SignalRContextState>(module.filename)

export { useSignalRContext }

export function SignalRProvider({ children }: ContextProps): ReactElement {
  const [signalRConnection, setSignalRConnection] = useState<HubConnection | null>(null)

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${API_BASE_URL}/hubs/printAgent`, { withCredentials: true })
      .withAutomaticReconnect()
      .build()

    newConnection.start().then(() => {
      setSignalRConnection(newConnection)
    })

    return () => {
      newConnection.stop()
    }
  }, [])

  return <Provider value={{ signalRConnection: signalRConnection }}>{children}</Provider>
}
