import { ReactElement } from 'react'
import './App.css'
import { AppRouter } from './routers/AppRouter'
import { User, useAuth0 } from '@auth0/auth0-react'
import { AuthLayout } from './components/layout/Auth/AuthLayout'
import { PlanType, Roles, UserStatusEnum } from './constants/common'
import { activateUser, fetchUser, saveUser } from './services/accounts'
import { HttpStatusCode } from 'axios'
import { User as CredsUser } from './models/manager/user'
import { useProfileContext } from './contexts/profileContext'
import { ProfileData } from './models/profileData'
import { EmailVerification } from './components/shared/EmailVerification'

function App(): ReactElement {
  const { isLoading, error, isAuthenticated, user, getAccessTokenSilently } = useAuth0()
  const { profile, saveProfile } = useProfileContext()

  const bypassAuthPaths = ['/kiosk', '/kiosk/:key']
  const isBypassPath = bypassAuthPaths.some((path) => location.pathname.startsWith(path.replace(':key', '')))

  if (isBypassPath) {
    return <AppRouter />
  }

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    return <AuthLayout isLoading={isLoading} />
  }

  if (!profile.IsImpersonating && isAuthenticated && profile.AuthUid != user?.sub) {
    if (!user?.sub || !user?.email) {
      return <div>Oops... authentication error occurred!</div>
    }

    if (!user.email_verified) {
      return (
        <AuthLayout isLoading={isLoading}>
          <EmailVerification usr={user} />
        </AuthLayout>
      )
    }

    // set the current account id to the first account this user is associated with
    // and store that to their profile
    getAccessTokenSilently().then(async (token) => {
      fetchUser(user.sub ?? '', token)
        .then((currentUser) => {
          setUserDataItems(currentUser, user, saveProfile, token)
        })
        .then(() => {
          window.location.reload()
        })
        .catch(async (fetchUserError) => {
          if (fetchUserError.response.status === HttpStatusCode.NotFound) {
            // no user record, so create it and an account for this new user
            await saveUser(
              {
                AuthUid: user.sub ?? '',
                Email: user.email ?? '',
                UpdatedBy: user.email ?? '',
              },
              token
            ).then(async () => {
              await fetchUser(user.sub ?? '', token)
                .then((currentUser) => {
                  setUserDataItems(currentUser, user, saveProfile, token)
                })
                .then(() => {
                  window.location.reload()
                })
            })
          }
        })
    })
  }

  return <AppRouter />
}

async function setUserDataItems(
  userData: CredsUser,
  user: User,
  saveProfile: (profile: ProfileData) => void,
  token: string
): Promise<void> {
  if (userData.AccountUsers && userData.AccountUsers.length > 0) {
    // process outstanding invitations
    for (let i = 0; i < userData.AccountUsers.length; i++) {
      const userAccount = userData.AccountUsers[i].AccountId
      if (userData.AccountUsers[i].UserStatusId === UserStatusEnum.Invited && user.sub && user.sub.length > 0) {
        activateUser(user.sub || '', userAccount, token)
      }
    }

    // save the user data to the profile context
    saveProfile({
      FirstName: userData.FirstName ?? '',
      LastName: userData.LastName ?? '',
      AccountId: userData.AccountUsers[0].AccountId,
      Email: user.email ?? '',
      AccountUid: userData.AccountUsers[0].Account?.AccountGuid ?? '',
      Permissions: userData.AccountUsers[0].Permissions ?? 0,
      AuthUid: user.sub ?? '',
      AccountName: userData.AccountUsers[0].Account?.Name ?? '',
      IsProfileComplete:
        userData.FirstName && userData.LastName && userData.AccountUsers[0].Account?.Name ? true : false,
      IsPlanComplete: userData.AccountUsers[0].Account?.PlanId == PlanType.NoneSelected ? false : true,
      Role: userData.AccountUsers[0].RoleId ?? Roles.User,
      AuthRoles: user['https://credsnow.com/roles'],
      IsImpersonating: false,
    })
  }
}

export default App
